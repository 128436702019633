import { TillCloseReview } from "@/interfaces/money";
import { EventBus } from "@/internal";
import { CloseTillFormModel } from "@/metadata/money";
import { MethodSales, moneyService } from "@/services/money.service";
import { PageNavAction } from "@/types/types";
import { truncate } from "@/utils/math.utils";
import forIn from "lodash/forIn";
import { subtract } from "mathjs";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./EmployeeCloseTill.template.vue";
import CloseTillSlipComponent from "./print/CloseTillSlip.component";

const billsMap: { [key: string]: number } = {
  ones: 1,
  fives: 5,
  tens: 10,
  twenties: 20,
  fifties: 50,
  hundreds: 100
};

@Component({
  mixins: [Template]
})
export default class EmployeeCloseTillComponent extends Vue {
  public paymentMethodsSales: MethodSales = {};
  public closeTillForm: CloseTillFormModel = {
    bills: {
      ones: "",
      fives: "",
      tens: "",
      twenties: "",
      fifties: "",
      hundreds: ""
    },
    total_change: "",
    other_amount: ""
  };
  public isValid = false;
  public isLoading = false;
  public differenceClass = "";
  public differenceSign = "";
  public tillData: TillCloseReview | null = null;
  public differenceRaw: number = 0;
  @Action("setPageNav", { namespace: "PageNavModule" })
  private setPageNav!: PageNavAction;

  public cancel() {
    this.$router.push("/");
  }

  public async save() {
    this.isLoading = true;
    const response = await moneyService.employeeCloseTill(
      this.tillData!.box.id,
      Number(this.employeeCount),
      this.closeTillForm
    );
    if (response && this.isValid) {
      EventBus.$emit("print", {
        component: CloseTillSlipComponent,
        props: {
          data: {
            expected_total: this.expectedCash,
            employee_count: this.employeeCount,
            difference: this.differenceRaw,
            open_timestamp: this.tillData!.open_date || "",
            ...response
          }
        }
      });
      this.cancel();
    }
    this.isLoading = false;
  }

  get employeeCount() {
    if (!this.isValid) {
      return 0;
    }
    let bills = 0;
    forIn(this.closeTillForm.bills, (value: string, key: string) => {
      bills = bills + billsMap[key] * +value;
    });
    return (
      bills +
      Number(this.closeTillForm.other_amount) +
      Number(this.closeTillForm.total_change)
    );
  }

  get startingCash() {
    if (this.tillData) {
      return this.tillData.cash_breakdown!.till_opening;
    }
    return 0;
  }

  get expectedCash() {
    if (this.tillData) {
      return this.tillData.cash_breakdown!.expected;
    }
    return 0;
  }

  get difference() {
    const employeeCount = +this.employeeCount;
    if (employeeCount !== 0 && !employeeCount) {
      this.differenceSign = this.differenceClass = "";
      return "--";
    }

    this.differenceRaw = Number(
      subtract(Number(this.employeeCount), Number(this.expectedCash))
    );

    if (this.differenceRaw > 0) {
      this.differenceClass = "positive";
      this.differenceSign = "+";
    } else if (this.differenceRaw < 0) {
      this.differenceClass = "negative";
      this.differenceSign = "-";
    } else {
      this.differenceSign = this.differenceClass = "";
    }
    return this.$options.filters!.currency(Math.abs(this.differenceRaw));
  }

  get notEmptyNums() {
    return (
      this.closeTillForm.bills.ones !== "" ||
      this.closeTillForm.bills.fives !== "" ||
      this.closeTillForm.bills.tens !== "" ||
      this.closeTillForm.bills.twenties !== "" ||
      this.closeTillForm.bills.fifties !== "" ||
      this.closeTillForm.bills.hundreds !== "" ||
      this.closeTillForm.total_change !== "" ||
      this.closeTillForm.other_amount !== ""
    );
  }

  public valueColors(value: number): string | null {
    return value > 0 ? "positive" : value < 0 ? "negative" : null;
  }

  public onlyNumber(event: KeyboardEvent) {
    if (
      isNaN(+event.key) &&
      !["."].includes(event.key) &&
      event.key.length === 1
    ) {
      event.preventDefault();
    } else if (!isNaN(+event.key) || ["."].includes(event.key)) {
      event.preventDefault();
      return event.key;
    }
  }

  public formatOther(event: KeyboardEvent) {
    const value = this.onlyNumber(event);
    if (value) {
      this.closeTillForm.other_amount = this.limitNumber(
        this.closeTillForm.other_amount + value
      );
    }
  }

  public formatTotal(event: KeyboardEvent) {
    const value = this.onlyNumber(event);
    if (value) {
      this.closeTillForm.total_change = this.limitNumber(
        this.closeTillForm.total_change + value
      );
    }
  }

  public limitNumber(num: string) {
    const hasMoreDots = num.includes("..");
    if (hasMoreDots) {
      return num.replace("..", ".");
    }
    if (num.includes(".")) {
      const splitNum = num.split(".");
      splitNum[0] = splitNum[0].substr(0, 7);
      splitNum[1] = splitNum[1].substr(0, 2);
      return splitNum.join(".");
    }
    return num.substr(0, 7);
  }

  protected async mounted() {
    this.tillData = await moneyService.getCurrentUserTillReview();
    this.paymentMethodsSales = moneyService.getSalesByMethod(this.tillData);
    this.setPageNav({
      title: "money_manager.employee_close_till",
      isLoading: () => this.isLoading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save,
            vuetifyProps: () => ({
              disabled: !this.notEmptyNums || !this.isValid,
              loading: this.isLoading,
              fab: true,
              small: true
            }),
            tooltip: {
              text: "money_manager.close_till_validation",
              displayCondition: () => !this.notEmptyNums || !this.isValid
            }
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.isLoading,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
  }
}
