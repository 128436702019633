import { Component, Mixins } from "vue-property-decorator";
import { MoneyPrintMixin } from "../../mixins/print.mixin";
import Template from "./CloseTillSlip.template.vue";

@Component({
  mixins: [Template]
})
export default class CloseTillSlipComponent extends Mixins(MoneyPrintMixin) {
  protected created() {
    this.$emit("readyToPrint");
  }
}
